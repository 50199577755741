import { Routes, Route, BrowserRouter } from "react-router-dom";
import { CareerIcons, HomeIcons, ServiceIcons } from "./ImgArray";
import Footer from "./Footer";
import { Navbar } from "./Navbar";
import Home from "./Home";
import ProductEngineering from "./ProductEngineering";
import DigitalTransform from "./DigitalTransform";
import RoboticProcessAutomation from "./RoboticProcessAutomation";
import DevOpsConsulting from "./DevOpsConsulting";
import Careers from "./Careers";
import Team from "./Team";
import ContactUs from "./ContactUs";
import FooterBanner from "./FooterBanner";


export function AppRouter() {

    return (
        <div className="container-fluid p-0">
            <BrowserRouter >
                <Navbar homeIcons={HomeIcons} serviceIcons={ServiceIcons} />
                <Routes>
                    <Route path='/' element={<Home />}></Route>
                    <Route path='/home' element={<Home />}></Route>
                    <Route path='/product-eng' element={<ProductEngineering serviceIcons={ServiceIcons} />}></Route>
                    <Route path='/digital-transform' element={<DigitalTransform serviceIcons={ServiceIcons} />}></Route>
                    <Route path='/rpa' element={<RoboticProcessAutomation homeIcons={HomeIcons} serviceIcons={ServiceIcons} />}></Route>
                    <Route path='/devops' element={<DevOpsConsulting serviceIcons={ServiceIcons} />}></Route>
                    <Route path='/careers/:id?' element={<Careers careerIcons={CareerIcons} />}></Route>
                    <Route path='/team' element={<Team careerIcons={CareerIcons} />}></Route>
                    <Route path='/contact-us' element={<ContactUs careerIcons={CareerIcons} />}></Route>
                </Routes>
                <FooterBanner homeIcons={HomeIcons} />
                <Footer homeIcons={HomeIcons} />
            </BrowserRouter>
        </div>
    )
}