import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { HomeProps } from './VarpProps';

// export default class Footer extends React.Component<HomeProps> {
  export default function Footer(props : HomeProps) {
  // render() {

  const [PrivacyPolicyModal, setPrivacyPolicyModal] = useState(false);

  const handlePrivacyPolicy = () => {
    setPrivacyPolicyModal(true);
  };

  const ClosePrivacyPolicy = () => {
    setPrivacyPolicyModal(false);
  };

  const [TermsOfUseModal, setTermsOfUseModal] = useState(false);

  const handleTermsOfUse = () => {
    setTermsOfUseModal(true);
  };

  const CloseTermsOfUse = () => {
    setTermsOfUseModal(false);
  };

    return (
      <div className='container-fluid'>
        <div className='container cont-fo'>
          <div className='Footer-Box'>
            <div className="fl fl-box">
              <img src={props.homeIcons.navLogo} alt="logo" className='logo-fo mb-2' />
            </div>
            <div className="fc fc-box">
              <p>
                <p><Link to='/home' className='nav-link f-1' >HOME </Link></p>
                <p className='my-1'><Link to='#' className='nav-link f-1' >SERVICES </Link></p>
                <p className='my-1'><Link to='/product-eng' className='nav-link f-2' >Product Engineering </Link></p>
                <p className='my-1'><Link to='/digital-transform' className='nav-link f-2' >Digital Transformation </Link></p>
                <p className='my-1'><Link to='/rpa' className='nav-link f-2' >Robotic Process Automation </Link></p>
                <p className='my-1'><Link to='/devops' className='nav-link f-2' >DevOps </Link></p>
                <p className='my-1'><Link to='/careers' className='nav-link f-1' >CAREERS </Link></p>
                <p className='my-1'><Link to='/team' className='nav-link f-1' >TEAM </Link></p>
              </p>
            </div>
            <div className="fr fr-box">
              <p>
                <p className='f-1 text-666666'>INQUIRE AT</p>
                <p className='p-1-1'>
                  <Link to='https://mail.google.com/mail/u/0/#inbox?compose=new&to=info@varptechlabs.com/' target='_blank' >
                    <img className='pr-1 imgbase' src={props.homeIcons.envelope} alt='envelop' />
                  </Link>
                  info@varptechlabs.com</p>
                <hr className='my-1'></hr>
                <Link to='https://www.linkedin.com/company/varp-techlabs/about/' target='_blank' className='text-light'>
                  <img className='pr-1' src={props.homeIcons.linkedin} alt='linkedin' />
                </Link>
                <Link to='https://twitter.com/' target='_blank' className='text-light'>
                  <img className='' src={props.homeIcons.twitter} alt='twitter' />
                </Link>
              </p>
            </div>
            <div className="cls"></div>
          </div>
        </div>
        <div className="container-fluid">
          <hr className='hr'></hr>
        <div className="container p-1">
          <div className="footer">
            <div className="fl">
              <p className='f-2 text-666666'>© Copyright 2023 VarpTechlabs. All rights reserved.</p>
            </div>
            <div className="fr py-1">
              <Link to="/" className="nav-link f-2 text-666666 pr-1" onClick={handlePrivacyPolicy}>Privacy Policy</Link>
              <Link to="/" className="nav-link f-2 text-666666" onClick={handleTermsOfUse}>Terms of use</Link>
            </div>
            <div className="cls"></div>
          </div>
        </div>
      </div>


        {/* <!-- PrivacyPolicyModal --> */}
        {PrivacyPolicyModal && (      
        <div className="modal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <p className="h-2">Privacy Policy</p>
                <button className='close-btn' onClick={ClosePrivacyPolicy}>X</button>
              </div>
              <div className="modal-body">
                <p className='f-2'>
                  At VARP Techlabs, we respect your privacy and are committed to protecting your personal information.
                  This Privacy Policy explains how we collect, use, and disclose your personal information when you visit
                  our website or use our services.
                </p>
                <p className='h-3 p-1'>Information We Collect</p>
                <p className='f-2'>
                  We may collect personal information about you when you visit our website, sign up for our newsletter,
                  or use our services. The types of personal information we may collect include your name, email address,
                  phone number, and company name.
                </p>
                <p className='f-2 p-1'>
                  We may also collect non-personal information about your visit to our website, such as your IP address,
                  browser type, and operating system. This information is used to help us improve our website and services.
                </p>
                <p className='h-3 p-1'>How We Use Your Information</p>
                <p className='f-2'>
                  We may use your personal information to communicate with you about our services, send you marketing materials,
                  and respond to your inquiries. We may also use your information to personalize your experience on our website and
                  to improve our services.
                </p>
                <p className='h-3 p-1'>Disclosure of Your Information</p>
                <p className='f-2'>
                  We do not sell or rent your personal information to third parties. However, we may disclose your information to
                  third-party service providers who help us operate our website or provide our services.
                </p>
                <p className='f-2 p-1'>
                  We may also disclose your information if required by law, such as in response to a court order or subpoena.
                </p>
                <p className='h-3 p-1'>Security of Your Information</p>
                <p className='f-2'>
                  We take the security of your personal information seriously and have implemented reasonable measures to protect it from
                  unauthorized access, use, or disclosure.
                </p>
                <p className='f-2 p-1'>
                  However, no security measures can provide 100% protection. Therefore, we cannot guarantee the security of your personal
                  information.
                </p>
                <p className='h-3 p-1'>Your Rights</p>
                <p className='f-2'>
                  You have the right to access, correct, or delete your personal information that we have collected. You may also request
                  that we stop using your information for marketing purposes.
                </p>
                <p className='f-2 p-1'>
                  To exercise these rights, please contact us using the information below.
                </p>
                <p className='h-3 p-1'>Changes to This Privacy Policy</p>
                <p className='f-2'>
                  We may update this Privacy Policy from time to time. If we make any material changes, we will notify you by email or by
                  posting a notice on our website.
                </p>
                <p className='h-3 p-1'>Contact Us</p>
                <p className='f-2'>
                  If you have any questions about this Privacy Policy or our practices, please contact us at info@varptechlabs.com.
                </p>
                <p className='f-2 p-1'>
                  By using our website or services, you consent to the collection and use of your personal information as described in
                  this Privacy Policy.
                </p>
              </div>
              <div className="modal-footer">
                <button onClick={ClosePrivacyPolicy} className="btn">Close</button>
              </div>
            </div>
          </div>
        </div>

)}

        {/* <!-- TermsOfUseModal --> */}
        {TermsOfUseModal && (  
        <div className="modal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <p className="h-2" id="TermsOfUseModalLabel">Terms of use</p>
                <button className="close-btn" onClick={CloseTermsOfUse}>X</button>
              </div>
              <div className="modal-body">
                <p className='f-2'>
                  These Terms of Use govern your use of VARP Techlabs website and services. By accessing or using our website or services,
                  you agree to be bound by these Terms of Use.
                </p>
                <p className='h-3 p-1'>Intellectual Property</p>
                <p className='f-2'>
                  All content on our website, including text, graphics, logos, and images, is the property of VARP Techlabs or its licensors and is
                  protected by copyright and other intellectual property laws.
                </p>
                <p className='f-2 p-1'>
                  You may not reproduce, distribute, or modify any content on our website without our prior written consent.
                </p>
                <p className='h-3 p-1'>Use of Our Services</p>
                <p className='f-2'>
                  Our services are provided on an “as is” and “as available” basis. We make no warranties or representations about the accuracy,
                  reliability, or completeness of our services.
                </p>
                <p className='f-2 p-1'>
                  You may use our services only for lawful purposes and in compliance with these Terms of Use. You may not use our services to
                  transmit or store any content that is illegal, infringes on someone else’s intellectual property rights, or is harmful or offensive.
                </p>

                <p className='h-3 p-1'>Limitation of Liability</p>
                <p className='f-2'>
                  To the extent permitted by law, VARP Techlabs shall not be liable for any indirect, incidental, special, or consequential damages
                  arising out of or in connection with your use of our website or services, even if we have been advised of the possibility of such damages.
                </p>
                <p className='h-3 p-1'>Indemnification</p>
                <p className='f-2'>
                  You agree to indemnify and hold VARP Techlabs and its affiliates, officers, agents, and employees harmless from any claim or demand,
                  including reasonable attorneys’ fees, arising out of or in connection with your use of our website or services, your breach of these
                  Terms of Use, or your violation of any law or the rights of a third party.
                </p>
                <p className='h-3 p-1'>Termination</p>
                <p className='f-2'>
                  We may terminate your access to our website or services at any time, without notice, for any reason or no reason at all.
                </p>
                <p className='h-3 p-1'>Governing Law and Jurisdiction</p>
                <p className='f-2'>
                  These Terms of Use shall be governed by and construed in accordance with the laws of [Jurisdiction], without giving effect to any
                  principles of conflicts of law.
                </p>
                <p className='f-2 p-1'>
                  Any disputes arising out of or in connection with these Terms of Use shall be resolved by the courts of [Jurisdiction].
                </p>
                <p className='h-3 p-1'>Changes to These Terms of Use</p>
                <p className='f-2'>
                  We may update these Terms of Use from time to time. If we make any material changes, we will notify you by email or by posting a
                  notice on our website.
                </p>
                <p className='h-3 p-1'>Contact Us</p>
                <p className='f-2'>
                  If you have any questions about these Terms of Use or our practices, please contact us at info@varptechlabs.com.
                </p>
                <p className='f-2 p-1'>
                  By using our website or services, you agree to be bound by these Terms of Use.
                </p>

              </div>
              <div className="modal-footer">
                <button type="button" className="btn" onClick={CloseTermsOfUse} >Close</button>
              </div>
            </div>
          </div>
        </div>
        )}



      </div>
    )
  }
// }
