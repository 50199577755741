import { useState } from 'react';
import { Link } from 'react-router-dom';
import { HomeProps, ServiceProps } from './VarpProps';

export function Navbar(props: HomeProps & ServiceProps) {

    // navbar background
    const [navbar_cont, setnavbar_cont] = useState(false);
    const changeBgNavbar = () => {
        if (window.scrollY >= 80) {
            setnavbar_cont(true);
        }
        else {
            setnavbar_cont(false);
        }
    };

    window.addEventListener('scroll', changeBgNavbar);

    // dropdown-list show
    const [dropdown, setDropdown] = useState(false);
    const handleDropdown = () => {
        setDropdown(!dropdown);
    };

    const [sidebar, setSidebar] = useState(false);
    const handleSidebar = () => {
        setSidebar(!sidebar);
    };

    const handleClose = () => {
        if (dropdown === true || sidebar === true) {
            setDropdown(false);
            setSidebar(false);
        }
    }

    return (
        <>
            <div className={navbar_cont ? 'container-fluid navbar_cont active' : 'container-fluid navbar_cont'}>
                <div className="container">
                    <div className="navbar" onClick={handleClose}>
                        <Link to='/' className="navbar-brand"><img src={props.homeIcons.navLogo} alt='VARP TECHLABS' /></Link>
                        <div className="toggle-btn" onClick={handleSidebar}>
                            <img src={props.serviceIcons.menu} alt='menu-icon' />
                        </div>
                        <ul className='nav-list' onClick={handleClose}>
                            <li className='nav-item'>
                                <Link to='/home' className="nav-link" aria-current="page" >Home</Link>
                            </li>
                            <li className='dropdown' onClick={handleDropdown}>
                                <Link to="#" className="nav-link">
                                    Services <div className='downarrow'></div>
                                </Link>
                                <div className={dropdown ? "dropdown-list active" : "dropdown-list"}>
                                    <div className="shape"></div>
                                    <li className='dropdown-item'>
                                        <Link to="/product-eng" className="nav-link">
                                            <img className='img-S' src={props.serviceIcons.tools} alt='tools' />
                                            Product Engineering</Link>
                                    </li>
                                    <li className='dropdown-item'>
                                        <Link to="/digital-transform" className="nav-link">
                                            <img className='img-S' src={props.serviceIcons.conPc} alt='computer-construction' />
                                            Digital Transform</Link>
                                    </li>
                                    <li className='dropdown-item'>
                                        <Link to="/rpa" className="nav-link">
                                            <img className='img-S' src={props.serviceIcons.robot} alt='robot' />
                                            Robotic Process Automation</Link>
                                    </li>
                                    <li className='dropdown-item'>
                                        <Link to="/devops" className="nav-link">
                                            <img className='img-S' src={props.serviceIcons.handCog} alt='hand-cog' />
                                            DevOps</Link>
                                    </li>
                                </div>
                            </li>
                            <li className='nav-item'>
                                <Link to='/careers' className="nav-link" >Careers</Link>
                            </li>
                            <li className='nav-item'>
                                <Link to='/team' className="nav-link" >Team</Link>
                            </li>
                        </ul>
                        <div className="flex">
                            <Link to="/contact-us"><button className="btn btn-text">GET IN TOUCH</button></Link>

                        </div>
                    </div>
                </div>
            </div>


            <div className={sidebar ? "sidebar active" : "sidebar"}>
                <ul className='sidebar-list'>
                    <li className='sidebar-item' onClick={handleSidebar}>
                        <Link to='/home' className="nav-link" aria-current="page" >Home</Link>
                    </li>
                    <li className='Sdropdown'>
                        <Link to="#" className="sidebar-link"> Services <div className='downarrow'></div></Link>
                    </li>
                    <ul className="Sdropdown-list">
                        <li className='Sdropdown-item' onClick={handleSidebar}>
                            <Link to="/product-eng" className="sidebar-link">
                                <img className='img-S' src={props.serviceIcons.tools} alt='tools' />
                                Product Engineering</Link>
                        </li>
                        <li className='Sdropdown-item' onClick={handleSidebar}>
                            <Link to="/digital-transform" className="sidebar-link">
                                <img className='img-S' src={props.serviceIcons.conPc} alt='computer-construction' />
                                Digital Transform</Link>
                        </li>
                        <li className='Sdropdown-item' onClick={handleSidebar}>
                            <Link to="/rpa" className="sidebar-link">
                                <img className='img-S' src={props.serviceIcons.robot} alt='robot' />
                                Robotic Process Automation</Link>
                        </li>
                        <li className='Sdropdown-item' onClick={handleSidebar}>
                            <Link to="/devops" className="sidebar-link">
                                <img className='img-S' src={props.serviceIcons.handCog} alt='hand-cog' />
                                DevOps</Link>
                        </li>
                    </ul>
                    <li className='sidebar-item' onClick={handleSidebar}>
                        <Link to='/careers' className="sidebar-link" >Careers</Link>
                    </li>
                    <li className='sidebar-item' onClick={handleSidebar}>
                        <Link to='/team' className="sidebar-link" >Team</Link>
                    </li>
                </ul>
                <div className="sidebar-flex" onClick={handleSidebar}>
                    <Link to="/contact-us"><button className="btn btn-text">GET IN TOUCH</button></Link>
                </div>
            </div>
        </>
    )
}
