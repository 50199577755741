import React, { Component } from 'react';
import { HomeProps } from './VarpProps';
import { Link } from 'react-router-dom';

export default class HomeSection5 extends Component<HomeProps> {
    render() {
        return (
            <div className="container cont5">
                <div className="OurServices-Box">
                    <div className="fl OurServices-fl-box">
                        <img className='img-border' src={this.props.homeIcons.dns} alt='server-icon' />
                        <p className='h-2'>
                            OUR SERVICES
                        </p>
                    </div>
                    <div className="OurServices-fr-box OurService-mt">
                        <div className="fc OurServices-box">
                            <img className='img' src={this.props.homeIcons.tools} alt='tools-icon' />
                            <p className='h-3'>
                                Product Engineering
                            </p>
                            <ul className='f-2 p-1-1'>
                                <li>Ideas to Concept</li>
                                <li>Product Development</li>
                                <li>Hybrid & Native App Development</li>
                                <li>API Development</li>
                                <li>Platform Engineering</li>
                                <li>SaaS Implementation</li>
                            </ul>
                            <Link to='/product-eng' className='f-2 p-1-1 f-re'>Read More
                                <img className='' src={this.props.homeIcons.arrow} alt='arrow' />
                            </Link>
                        </div>
                        <div className="fr m-l OurServices-box2">
                            <img className='img' src={this.props.homeIcons.pc} alt='monitor-icon' />
                            <p className='h-3'>
                                Digital Transformation
                            </p>
                            <ul className='f-2 p-1-1'>
                                <li>Planning & Strategy</li>
                                <li>Technical Implementation</li>
                                <li>Legacy Modernization</li>
                                <li>Application Development</li>
                                <li>AI/ML & Automation</li>
                            </ul>
                            <Link to='/digital-transform' className='f-2 p-1-1 f-re'>Read More
                                <img className='' src={this.props.homeIcons.arrow} alt='arrow' />
                            </Link>
                        </div>
                    </div>
                    <div className="cls"></div>
                </div>
                <div className="OurServices-Box OurService-mb">
                    <div className="fl OurServices-fl-box">&nbsp;</div>
                    <div className="OurServices-fr-box">
                        <div className="fc OurServices-box">
                            <img className='img' src={this.props.homeIcons.robot} alt='robot-icon' />
                            <p className='h-3'>
                                Robotic Process Automation
                            </p>
                            <ul className='f-2 p-1-1'>
                                <li>RPA Consulting</li>
                                <li>Automation Design</li>
                                <li>RPA Development</li>
                                <li>Infrastructure & Automation support</li>
                            </ul>
                            <Link to='/rpa' className='f-2 p-1-1 f-re'>Read More
                                <img className='' src={this.props.homeIcons.arrow} alt='arrow' />
                            </Link>
                        </div>
                        <div className="fr OurServices-box2">
                            <img className='img' src={this.props.homeIcons.cog} alt='cog-tool-icon' />
                            <p className='h-3'>
                                DevOps
                            </p>
                            <ul className='f-2 p-1-1'>
                                <li>Containerization via ECS/Kubernetes</li>
                                <li>AWS/Azure/GCP Operations</li>
                                <li>Cost Optimization</li>
                                <li>Partner in every step in your cloud journey</li>
                            </ul>
                            <Link to='/devops' className='f-2 p-1-1 f-re'>Read More
                                <img className='' src={this.props.homeIcons.arrow} alt='arrow' />
                            </Link>
                        </div>
                    </div>
                    <div className="cls"></div>
                </div>
            </div>
        )
    }
}
