import React, { Component } from 'react';
import { CareerProps } from './VarpProps';

export default class Team extends Component<CareerProps> {
    render() {
        window.scrollTo(0, 0);
        return (
            <>
                <div className="container-fluid cont-Services">
                    <div className="container cont-galaxy">
                        <div className="S-h1">
                            <img className='img-border' src={this.props.careerIcons.team} alt='tools-icon' />
                            <p className='h-1 Services-head-1'>
                                The VARP Team
                            </p>
                        </div>
                        <div className="S-h2">
                            <p className='f-1'>
                                We're a small and fast growing team of engineers,<br />
                                designers, and dreamers working together to make products.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="container Team">
                            <div className="text-center py-5">
                                <p className='h-2'>Core Members</p>
                            </div>
                            <div className="Member-Box">
                                <div className="fl CM-box">
                                    <img className='' src={this.props.careerIcons.founder} alt='founder-img' />
                                    <p className='f-1'>Prashant Tyagi</p>
                                    <p className='f-2'>Founder</p>
                                </div>
                                <div className="fr CM-box">
                                    <img className='' src={this.props.careerIcons.co_founder_1} alt='founder-img' />
                                    <p className='f-1'>Ravi Singh</p>
                                    <p className='f-2'>Co-Founder</p>
                                </div>
                                {/* <div className="fr CM-box">
                                    <img className='' src={this.props.careerIcons.co_founder_2} alt='founder-img' />
                                    <p className='f-1'>Pramod Pawar</p>
                                    <p className='f-2'>Co-Founder</p>
                                </div> */}
                                <div className="cls"></div>
                            </div>

                            <div className="Values-Box">
                                <div className="text-center py-5">
                                    <p className="h-2">OUR VALUES</p>
                                </div>

                                <hr className='text-light hr'></hr>

                                <div className="p-5">
                                    <div className="fl">
                                        <img className="pr-1" src={this.props.careerIcons.time} alt='time-icon' />
                                        <p className='img-p h-2'>Invent the future</p>
                                    </div>
                                    <div className="fr">
                                        <p className='f-1'>
                                            Our focus is on creating new value in the world, rather than making marginal
                                            improvements to existing processes. We believe that the best way to predict the
                                            future is by inventing it, and we prioritize learning through action. Our approach
                                            is optimized for long-term success.
                                        </p>
                                    </div>
                                    <div className="cls"></div>
                                </div>

                                <hr className='text-light hr'></hr>

                                <div className="p-5">
                                    <div className="fl">
                                        <img className="pr-1" src={this.props.careerIcons.happyMood} alt='happyFace-icon' />
                                        <p className='img-p h-2'>Relentless optimism</p>
                                    </div>
                                    <div className="fr">
                                        <p className='f-1'>
                                            We hold an optimistic view of the future, where progress in creativity is not only achievable, but also inevitable. Our optimism, however, is not without a sense of realism. We understand that the journey towards this future will present numerous challenges, and some may be difficult to overcome. Nevertheless, we remain
                                            confident that, as a team, we can overcome these obstacles and succeed together.
                                        </p>
                                    </div>
                                    <div className="cls"></div>
                                </div>

                                <hr className='text-light hr'></hr>

                                <div className="p-5">
                                    <div className="fl">
                                        <img className="pr-1" src={this.props.careerIcons.brain} alt='brain-icon' />
                                        <p className='img-p h-2'>Try fast, learn fast</p>
                                    </div>
                                    <div className="fr">
                                        <p className='f-1'>
                                            Our team comprises of ambitious dreamers who strive for success.
                                            We believe that our progress is dependent on how quickly we learn,
                                            which often involves trial and error. We embrace the idea of learning
                                            from our mistakes and continuously improving ourselves. We stay humble,
                                            and prioritize learning something new every day.
                                        </p>
                                    </div>
                                    <div className="cls"></div>
                                </div>

                                <hr className='text-light hr'></hr>

                                <div className="p-5">
                                    <div className="fl">
                                        <img className="pr-1" src={this.props.careerIcons.earth} alt='earth-icon' />
                                        <p className='img-p h-2'>Solve for our users.<br /><span className='mr-3'>Solve with care.</span></p>
                                    </div>
                                    <div className="fr">
                                        <p className='f-1'>
                                            Our values are reflected in our methodology as we deeply care
                                            about what we are building and for whom we are building it.
                                            At the core of our operations are principles of inclusion,
                                            transparency, and respect for people from diverse backgrounds.
                                            We take pride in having a team that represents different walks of life,
                                            and we believe that these values are essential for our success.
                                        </p>
                                    </div>
                                </div>

                            </div>

                    </div>
                </div>
            </>
        )
    }
}
