import { Component } from 'react';
import { ServiceProps } from './VarpProps';

export default class DevOpsConsulting extends Component<ServiceProps> {
  render() {
    window.scrollTo(0, 0);
    return (
      <>
        <div className="container-fluid cont-Services">
          <div className="container cont-galaxy">
              <div className="S-h1">
                <img className='img-border' src={this.props.serviceIcons.tools} alt='tools-icon' />
                <p className='h-1 Services-head-1'>
                  DevOps Consulting
                </p>
              </div>
              <div className="S-h2">
                <p className='f-1'>
                  Embrace the power of DevOps and take your business to the next level with our expert consulting services.
                  Our team of experienced professionals will work with you to implement a DevOps strategy tailored to your business needs,
                  helping you achieve faster time-to-market, higher quality, and increased customer satisfaction.
                </p>
              </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="container contS-DC">
            <div className="text-center py-5">
              <p className='h-2'>
                DevOps Consulting Services
              </p>
            </div>
            <div className="row">
              <div className="">
                <div className="tile">
                  <p className='f-1'>
                    Review & build a future-proof DevOps strategy with industry best practices
                  </p>
                </div>
                <div className="tile">
                  <p className='f-1'>
                    Infrastructure as a Code approach (IaaC)
                  </p>
                </div>
                <div className="tile">
                  <p className='f-1'>
                    Containerization of your Applications
                  </p>
                </div>
                <div className="tile">
                  <p className='f-1'>
                    Introducing application monitoring
                  </p>
                </div>
                <div className="tile">
                  <p className='f-1'>
                    Introducing application logging
                  </p>
                </div>
                <div className="tile">
                  <p className='f-1'>
                    Integrate Infrastructure with CI/CD Tools
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>


      </>
    )
  }
}
