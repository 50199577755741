import React from 'react';
import { HomeProps } from './VarpProps';
import { Link, useLocation } from 'react-router-dom';

export default function FooterBanner(props: HomeProps) {

  const location = useLocation();
  const hiddenRoutes = ['/contact-us'];

  if (hiddenRoutes.includes(location.pathname)) {
    return null;
  }

  return (
    <div className='container-fluid cont-bn'>
      <div className='container banner'>
            <div className="fl fl-box">
              <p className='h-1 footer-banner-font-size'>
                LET'S GET STARTED ON
                SOMETHING GREAT!
              </p>
            </div>
            <div className="fr fr-box">
              <Link to="/contact-us"><button className="btn-bn bn-btn">CONTACT NOW <img className='img-arrow' src={props.homeIcons.Btnarrow} alt='arrow-icon' /></button></Link>
            </div>
            <div className="cls"></div>
          </div>
    </div>
  )
}
