import React, { useEffect, useState } from 'react';
import { CareerProps } from './VarpProps';
import { HashLink as Link } from 'react-router-hash-link';
import { useLocation } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { CircleLoader } from 'react-spinners';

export default function Careers(props: CareerProps) {

  // render() {

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/careers') {
      window.scrollTo(0, 0);
    }
  }, [location]);
  

  const [JobApplyModal, setJobApplyModal] = useState(false);

  // const handleJobApply = () => {
  //   setJobApplyModal(true);
  // };

  const CloseJobApply = () => {
    setJobApplyModal(false);
  };

  const [verified, setVerified] = useState(true);
  function onChange(value: any) {
    setVerified(false);
  }


  const url = 'https://script.google.com/macros/s/AKfycbx-k5cOC34BzV7juFpdWyGl9Yql2R65VnkpendxkMO8SPtiUKxdeOtTLpPA3ptZF4oV/exec';

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [linkedin, setLinkedIn] = useState("");
  const [job_profile, setJobProfile] = useState("");
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState(false);

  const handleJobProfile = (event: any) => {
    const button = event.target;
    const recipientValue = button.getAttribute('data-bs-whatever');
    setJobProfile(recipientValue);
    setJobApplyModal(true);
  };

  const handleInputChange = (event: any) => {
    setJobProfile(event.target.value);
  };

  function handleFile(event: any) {
    const fileurl = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = function (event) {
      const rawLog = reader.result?.toString().split(',')[1];
      const myString = name;
      const fileNewName = myString.replace(/\s+/g, "").trim();
      const obj = { data: rawLog, name: fileurl.name, type: fileurl.type, fname: fileNewName + mobile };
      setFile(JSON.stringify(obj));
    }
  }

  function handleSubmit(event: any) {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 9000)

    event.preventDefault();

    const formData = new FormData();
    formData.append("Name", name);
    formData.append("Email", email);
    formData.append("Mobile", mobile);
    formData.append("JobProfile", job_profile);
    formData.append("LinkedIn", linkedin);
    formData.append("File", file);

    fetch(url, {
      method: "POST",
      body: formData,
      mode: "no-cors"
    })
      .then(res => {
        alert('Form submitted successfully');
        window.location.reload();
      })
      .catch(error => {
        alert("Error submitting form:" + error);
      });
  }

  return (
    <>
      <div className="container-fluid cont-Services">
        <div className="container cont-galaxy">
          <div className="S-h1">
            <img className='img-border' src={props.careerIcons.team} alt='tools-icon' />
            <p className='h-1 Services-head-1'>
              Join The Team
            </p>
          </div>
          <div className="S-h2">
            <p className='f-1'>
              We're a small and fast growing team of engineers,<br />
              designers, and dreamers working together to make products.
            </p>
            <Link to='#form' className=''>
              <button className='btn btn-text'>BROWSE POSITIONS
                <img className='arrowDown' src={props.careerIcons.arrowDown} alt='arrow-down' />
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="container Career">
          <div id='form' className="Career-Box">
            <div className="fl">
              <p className='h-2'>
                OPEN <br />POSITIONS
              </p>
            </div>
            <div className="fr">
              <p className='f-1'>
                We welcome talented individuals from diverse backgrounds
                and perspectives who share our passion for creating exceptional
                software experiences. Our team thrives in a collaborative and
                flexible work environment that encourages open-mindedness
                and creativity. Come join us and be part of a dynamic team
                that values diversity, creativity, and excellence.
              </p>
            </div>
            <div className="cls"></div>
          </div>

          <div className="row">

            <div className="tile">
              <div className="fl">
                <p className='f-1'>ReactJS Engineer</p>
              </div>
              <div className="fc">
                <p className='f-2'>Engineering</p>
              </div>
              <div className="fc">
                <p className='f-2'>Hybrid / Pune, India</p>
              </div>
              <div className="fr">
                <button className='btn btn-text' onClick={handleJobProfile} data-bs-whatever="ReactJS Engineer">Apply</button>
              </div>
              <div className="cls"></div>
            </div>

            <div className="tile">
              <div className="fl">
                <p className='f-1'>Java Engineer</p>
              </div>
              <div className="fc">
                <p className='f-2'>Engineering</p>
              </div>
              <div className="fc">
                <p className='f-2'>Hybrid / Pune, India</p>
              </div>
              <div className="fr">
                <button className='btn btn-text' onClick={handleJobProfile} data-bs-whatever="Java Engineer">Apply</button>
              </div>
              <div className="cls"></div>
            </div>

            <div className="tile">
              <div className="fl">
                <p className='f-1'>DevOps Engineer</p>
              </div>
              <div className="fc">
                <p className='f-2'>Engineering</p>
              </div>
              <div className="fc">
                <p className='f-2'>Hybrid / Pune, India</p>
              </div>
              <div className="fr">
                <button className='btn btn-text' onClick={handleJobProfile} data-bs-whatever="DevOps Engineer">Apply</button>
              </div>
              <div className="cls"></div>
            </div>

            <div className="tile">
              <div className="fl">
                <p className='f-1'>Program Manager</p>
              </div>
              <div className="fc">
                <p className='f-2'>Engineering</p>
              </div>
              <div className="fc">
                <p className='f-2'>Hybrid / Pune, India</p>
              </div>
              <div className="fr">
                <button className='btn btn-text' onClick={handleJobProfile} data-bs-whatever="Program Manager">Apply</button>
              </div>
              <div className="cls"></div>
            </div>

            {JobApplyModal && (
              <div className="modal-sm">
                <div className="modal-dialog-sm">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1 className="modal-title" hidden={loading!} id="JobModalLabel">JOB APPLICATION FORM</h1>
                      <h1 className="modal-title" hidden={!loading} id="JobModalLabel">Loading.......</h1>
                      <button className='close-btn' onClick={CloseJobApply}>X</button>
                    </div>
                    <div className="modal-body">
                      <div className="form">
                        {
                          loading ?
                            <div className='loading'><CircleLoader color="#36d7b7" loading={loading} size={100} /></div> :
                            <form onSubmit={handleSubmit}>
                              <div className="fl">
                                <label htmlFor="job_profile" className="formLabel">Job Profile *</label>
                                <input type='text' className="txt" id="job_profile" value={job_profile} name='job_profile' onChange={handleInputChange} disabled />
                              </div>
                              <div className="fr">
                                <label htmlFor="name" className="formLabel">Your Name *</label>
                                <input type="text" className="txt" id="name" value={name} name='name' onChange={e => setName(e.target.value)} placeholder="Enter Name" pattern='[a-z,A-Z," "]{2,50}' required />
                              </div>
                              <div className="cls"></div>
                              <div className="fl">
                                <label htmlFor="emailId" className="formLabel">Email address *</label>
                                <input type="email" className="txt" id="emailId" value={email} name='email' onChange={e => setEmail(e.target.value)} placeholder="Enter Email Id" required />
                              </div>
                              <div className="fr">
                                <label htmlFor="mobile" className="formLabel">Enter Mobile *</label>
                                <input type='text' className="txt" id="mobile" value={mobile} name='mobile' onChange={e => setMobile(e.target.value)} placeholder='Enter Mobile' required />
                              </div>
                              <div className="cls"></div>
                              <div className="fl">
                                <label htmlFor="linkedin" className="formLabel">LinkedIn Url *</label>
                                <input type='text' className="txt" id="linkedin" value={linkedin} name='linkedin' onChange={e => setLinkedIn(e.target.value)} placeholder='Enter LinkedIn Url' required />
                              </div>
                              <div className="fr">
                                <label htmlFor="file" className="formLabel">pdf/doc file only *</label>
                                <input type='file' className="file" id="file" name='file' onChange={(e) => handleFile(e)} />
                              </div>
                              <div className="cls"></div>
                              <div className="modal-footer">
                                {/* <ReCAPTCHA className='captcha' sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" onChange={onChange} /> */}
                                {/* <button className='btn-submit btn-txt' disabled={verified} type='submit'>SUBMIT REQUEST</button> */}
                                <button className='btn-submit btn-txt' type='submit'>SUBMIT REQUEST</button>
                              </div>
                            </form>
                        }
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            )}
          </div>

          
              <div className="Perks-Box">
                <p className="h-p-2 py-2">Perks & Benefits</p>
                  <div className="fl">
                    <p className="h-2">Health Insurance</p>
                    <p className="f-2">
                      Your health is our priority - choose our
                      comprehensive insurance plans as your
                      employee benefit
                    </p>
                  </div>
                  <div className="fr">
                    <p className="h-2">Life insurance</p>
                    <p className="f-2">
                      Our life insurance perk is more than just a
                      benefit. It's a promise to support you and your
                      family when it matters most.
                    </p>
                  </div>
                  <div className="fl">
                    <p className="h-2">Hybrid Work Model</p>
                    <p className="f-2">
                      Adapt to the future of work with our
                      innovative hybrid work model.
                    </p>
                  </div>
                  <div className="fr">
                    <p className="h-2">Workation</p>
                    <p className="f-2">
                      Enjoy the best of both worlds - work and
                      vacation - with our workation policy.
                    </p>
                  </div>
              </div>

            <div className="Values-Box">
              <div className="text-center">
                <p className="h-2 pb-5">OUR VALUES</p>
              </div>

              <hr className='hr'></hr>

              <div className="p-5">
                <div className="fl">
                  <img className="pr-1" src={props.careerIcons.time} alt='time-icon' />
                  <p className='img-p h-2'>Invent the future</p>
                </div>
                <div className="fr">
                  <p className='f-1'>
                    Our focus is on creating new value in the world, rather than making marginal
                    improvements to existing processes. We believe that the best way to predict the
                    future is by inventing it, and we prioritize learning through action. Our approach
                    is optimized for long-term success.
                  </p>
                </div>
                <div className="cls"></div>
              </div>

              <hr className='hr'></hr>

              <div className="p-5">
                <div className="fl">
                  <img className="pr-1" src={props.careerIcons.happyMood} alt='happyFace-icon' />
                  <p className='img-p h-2'>Relentless optimism</p>
                </div>
                <div className="fr">
                  <p className='f-1'>
                    We hold an optimistic view of the future, where progress in creativity is not only achievable, 
                    but also inevitable. Our optimism, however, is not without a sense of realism. We understand that 
                    the journey towards this future will present numerous challenges, and some may be difficult to overcome.
                     Nevertheless, we remain confident that, as a team, we can overcome these obstacles and succeed together.
                  </p>
                </div>
                <div className="cls"></div>
              </div>

              <hr className='hr'></hr>

              <div className="p-5">
                <div className="fl">
                  <img className="pr-1" src={props.careerIcons.brain} alt='brain-icon' />
                  <p className='img-p h-2'>Try fast, learn fast</p>
                </div>
                <div className="fr">
                  <p className='f-1'>
                    Our team comprises of ambitious dreamers who strive for success.
                    We believe that our progress is dependent on how quickly we learn,
                    which often involves trial and error. We embrace the idea of learning
                    from our mistakes and continuously improving ourselves. We stay humble,
                    and prioritize learning something new every day.
                  </p>
                </div>
                <div className="cls"></div>
              </div>

              <hr className='hr'></hr>

              <div className="p-5">
                <div className="fl">
                  <img className="pr-1" src={props.careerIcons.earth} alt='earth-icon' />
                  <p className='img-p h-2'>Solve for our users.<br/><span className='mr-3'>Solve with care.</span></p>
                </div>
                <div className="fr">
                  <p className='f-1'>
                    Our values are reflected in our methodology as we deeply care
                    about what we are building and for whom we are building it.
                    At the core of our operations are principles of inclusion,
                    transparency, and respect for people from diverse backgrounds.
                    We take pride in having a team that represents different walks of life,
                    and we believe that these values are essential for our success.
                  </p>
                </div>
                <div className="cls"></div>
              </div>

            </div>
            </div>
          </div>

    </>
  )
}
// }
