import React, { Component } from 'react';
import { HomeProps } from './VarpProps';

export default class HomeSection6 extends Component<HomeProps> {
  render() {
    return (
      <div className="container-fluid cont2">
        <div className="container">
        <div className="box">
          <img className='img-border' src={this.props.homeIcons.db} alt='database-icon' />
            <p className='h-2 p-2'>
              TECHNOLOGIES WE USE
            </p>
            <p className='f-1 p-2'>
                Empowering your business with cutting-edge technologies.
              </p>
          </div>
            <div className="p-2">
                <div className="Tech-box p-2">
                    <img className='border-right' src={this.props.homeIcons.dotnet} alt='dotnet-icon' title='Microsoft .NET' />
                    <img className='border-right' src={this.props.homeIcons.java} alt='java-icon' title='Java' />
                    <img className='border-right' src={this.props.homeIcons.python} alt='python-icon' title='Python' />
                    <img className='border-right' src={this.props.homeIcons.golan} alt='golan-icon' title='GoLang' />
                    <img className='border-right' src={this.props.homeIcons.angular} alt='angular-icon' title='Angular'/>
                    <img className='border-right' src={this.props.homeIcons.react} alt='react-icon' title='React' />
                    <img className='border-right' src={this.props.homeIcons.figma} alt='figma-icon' title='Figma' />
                    <img className='border-right' src={this.props.homeIcons.node} alt='nodejs-icon' title='NodeJS' />
                    <img className='border-right' src={this.props.homeIcons.aws} alt='aws-icon' title='AWS' />
                    <img src={this.props.homeIcons.gcloud} alt='google-cloud-icon' title='Google Cloud' />
                </div>
                <div className="Tech-box">
                    <img className='border-right border-top' src={this.props.homeIcons.azure} alt='azure-icon' title='Microsoft Azure' />
                    <img className='border-right border-top' src={this.props.homeIcons.kuber} alt='kubernetes-icon' title='Kubernetes' />
                    <img className='border-right border-top' src={this.props.homeIcons.uipath} alt='uipath-icon' title='UIPath' />
                    <img className='border-right border-top' src={this.props.homeIcons.selenium} alt='selenium-icon' title='Selenium' />
                    <img className='border-right border-top' src={this.props.homeIcons.apacheM} alt='apache-jmeter-icon' title='Apache JMeter' />
                    <img className='border-right border-top' src={this.props.homeIcons.docker} alt='docker-icon' title='Docker' />
                    <img className='border-right border-top' src={this.props.homeIcons.mongodb} alt='mongodb-icon' title='MongoDB' />
                    <img className='border-right border-top' src={this.props.homeIcons.sqlserver} alt='sql-server-icon' title='SQL Server' />
                    <img className='border-right border-top' src={this.props.homeIcons.awsdb} alt='dynamo-db-icon' title='DynamoDB' />
                    <img className='border-top' src={this.props.homeIcons.postgre} alt='postgre-sql-icon' title='PostgreSQL' />
                </div>
            </div>
        </div>
      </div>
    )
  }
}
