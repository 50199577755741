import { Component } from 'react';
import { HomeProps } from './VarpProps';
import { Link } from 'react-router-dom';

export default class HomeSection1 extends Component<HomeProps> {
    render() {
        return (
            <div className="container-fluid cont-frank">
                <div className="container cont1">
                    <div className="box1">
                        <p className='h-1'>
                            Transforming your digital world
                            with innovative solutions
                        </p>
                    </div>
                    <div className="box2">
                        <p className='f-1'>
                            Transform the way you do business with our exciting and innovative product development,
                            which empowers you to launch your offerings into the market at WARP speed!
                        </p>
                    </div>
                    <div className="box2">
                        <Link to='/contact-us'><button className="btn-start">START YOUR PROJECT <img className='img-arrow' src= {this.props.homeIcons.Btnarrow} alt='arrow-icon'/></button></Link>
                    </div>
                </div>
            </div>
        )
    }
}
