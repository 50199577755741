import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { CareerProps } from './VarpProps';
import { CircleLoader } from 'react-spinners';

export default function ContactUs(props: CareerProps) {
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/contact-us') {
            window.scrollTo(0, 0);
        }
    }, [location]);

    const [verified, setVerified] = useState(true);
    function onChange(value: any) {
        setVerified(false);
    }

    // Active button show
    const [active_btn1, setActive_btn1] = useState(false);
    const [active_btn2, setActive_btn2] = useState(false);
    const [active_btn3, setActive_btn3] = useState(false);
    const [active_btn4, setActive_btn4] = useState(false);
    const [active_btn5, setActive_btn5] = useState(false);

    const handleActive_btn_1 = () => {
        if (active_btn1 === false) {
            setActive_btn1(true);
        }
        else if (active_btn1 === true) {
            setActive_btn1(false);
        }
    };

    const handleActive_btn_2 = () => {
        if (active_btn2 === false) {
            setActive_btn2(true);
        }
        else if (active_btn2 === true) {
            setActive_btn2(false);
        }
    };

    const handleActive_btn_3 = () => {
        if (active_btn3 === false) {
            setActive_btn3(true);
        }
        else if (active_btn3 === true) {
            setActive_btn3(false);
        }
    };

    const handleActive_btn_4 = () => {
        if (active_btn4 === false) {
            setActive_btn4(true);
        }
        else if (active_btn4 === true) {
            setActive_btn4(false);
        }
    };

    const handleActive_btn_5 = () => {
        if (active_btn5 === false) {
            setActive_btn5(true);
        }
        else if (active_btn5 === true) {
            setActive_btn5(false);
        }
    };

    // const url = 'https://script.google.com/macros/s/AKfycbwla94eWe__X4zsJAcrfc3TLNFruFWpjmPA9wU6Nu66DATQqHNtNwZTT3o3hAVMPNOaIw/exec';

    // const [name, setName] = useState("");
    // const [email, setEmail] = useState("");
    // const [message, setMessage] = useState("");
    // const [loading, setLoading] = useState(false);

    // interface Sers {
    //     [key: string]: boolean;
    // }
    // const [sers, setSers] = useState<Sers>({
    //     Web_Development: false,
    //     Ui_Ux_Design: false,
    //     Mobile_Development: false,
    //     Robotics_Process_Automation: false,
    //     Others: false,
    // });

    // const handleCheckboxChange = (e: any) => {
    //     const { name, checked } = e.target;
    //     setSers(prevState => ({
    //         ...prevState,
    //         [name]: checked,
    //     }));
    // };

    // const selectedServices = Object.keys(sers).filter(key => sers[key]);
    // const selectedServicesJSON = JSON.stringify(selectedServices);

    // function handleSubmit(event: any) {
    //     setLoading(true);
    //     setTimeout(() => {
    //         setLoading(false);
    //     }, 9000)

    //     event.preventDefault();

    //     const formData = new FormData();
    //     formData.append("Name", name);
    //     formData.append("Email", email);
    //     formData.append("Message", message);
    //     formData.append("Services", JSON.stringify(selectedServicesJSON));

    //     fetch(url, {
    //         method: "POST",
    //         body: formData,
    //         mode: "no-cors"
    //     }).then(res => {
    //         alert('Form submitted successfully');
    //         window.location.reload();
    //     })
    //         .catch(error => {
    //             alert("Error submitting form:" + error);
    //         });
    // }

    const url = 'https://script.google.com/macros/s/AKfycbwla94eWe__X4zsJAcrfc3TLNFruFWpjmPA9wU6Nu66DATQqHNtNwZTT3o3hAVMPNOaIw/exec';

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    interface Sers {
        [key: string]: boolean;
    }
    const [sers, setSers] = useState<Sers>({
        Web_Development: false,
        Ui_Ux_Design: false,
        Mobile_Development: false,
        Robotics_Process_Automation: false,
        Others: false,
    });

    const handleCheckboxChange = (e: any) => {
        const { name, checked } = e.target;
        setSers(prevState => ({
            ...prevState,
            [name]: checked,
        }));
    };

    const selectedServices = Object.keys(sers).filter(key => sers[key]);
    const selectedServicesJSON = JSON.stringify(selectedServices);

    async function handleSubmit(event: any) {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 9000)

        event.preventDefault();

        const formData = new FormData();
        formData.append("Name", name);
        formData.append("Email", email);
        formData.append("Message", message);
        formData.append("Services", JSON.stringify(selectedServicesJSON));

        try {
            const response = await fetch(url, {
                method: "POST",
                body: formData,
                mode: "no-cors"
            });
            if (response.ok) {
                alert('Form submitted successfully');
                window.location.reload();
            } else {
                throw new Error('Network response was not ok.');
            }
        } catch (error) {
            alert("Error submitting form:" + error);
        } finally {
            setLoading(false);
        }
    }


    return (
        <>

            <div className="container-fluid cont-Services">
                <div className="container">
                    <div className="Contact-Box">
                        <p className="txtbox txt-UK-Map">LONDON, UNITED KINGDOM</p>
                        <img className='UK-Map' src={props.careerIcons.map_location} alt='' />

                        <img className='IN-Map' src={props.careerIcons.map_location} alt='' />
                        <div className="txtbox txt-IN-Map">PUNE, INDIA</div>
                    </div>
                </div>
            </div>

            <div className="">
                <div className="container Contact">
                    <div className="Contact-form">
                        <div className="form">
                            <p hidden={loading!} className='h-2'>WE'D LOVE TO HEAR<br /> FROM YOU</p>
                            <p hidden={!loading} className='h-2'>Loading.......</p>
                            {
                                loading ?
                                    <div className='loading'><CircleLoader color="#36d7b7" loading={loading} size={100} /></div> :
                                    <form onSubmit={handleSubmit}>
                                        <div className="py-2">
                                            <label className="formLabel">I’m Interested in...</label>
                                        </div>
                                        <div className="cbox">
                                            <label htmlFor='btn-1' onClick={handleActive_btn_1} className={active_btn1 ? 'btn-select-1 btn-select-txt active_btn1' : 'btn-select-1 btn-select-txt'}>Web Development</label>
                                            <label htmlFor='btn-2' onClick={handleActive_btn_2} className={active_btn2 ? 'btn-select-2 btn-select-txt active_btn2' : 'btn-select-2 btn-select-txt'}>UI/UX Design</label>
                                            <label htmlFor='btn-3' onClick={handleActive_btn_3} className={active_btn3 ? 'btn-select-3 btn-select-txt active_btn3' : 'btn-select-3 btn-select-txt'}>Mobile Development</label>
                                            <input type='checkbox' name='Web_Development' onChange={handleCheckboxChange} id='btn-1' hidden />
                                            <input type='checkbox' name='Ui_Ux_Design' onChange={handleCheckboxChange} id='btn-2' hidden />
                                            <input type='checkbox' name='Mobile_Development' onChange={handleCheckboxChange} id='btn-3' hidden />
                                        </div>
                                        <div className="cbox py-3">
                                            <label htmlFor='btn-4' onClick={handleActive_btn_4} className={active_btn4 ? 'btn-select-4 btn-select-txt active_btn4' : 'btn-select-4 btn-select-txt'}>Robotic Process Automation</label>
                                            <label htmlFor='btn-5' onClick={handleActive_btn_5} className={active_btn5 ? 'btn-select-5 btn-select-txt active_btn5' : 'btn-select-5 btn-select-txt'}>Other</label>
                                            <input type='checkbox' name='Robotics_Process_Automation' onChange={handleCheckboxChange} id='btn-4' hidden />
                                            <input type='checkbox' name='Others' onChange={handleCheckboxChange} id='btn-5' hidden />
                                        </div>
                                        <div className="">
                                            <label htmlFor="name" className="formLabel">Enter Your Name</label>
                                            <input type="text" className="txt" id="name" value={name} name='name' onChange={e => setName(e.target.value)} placeholder="Enter Name" pattern='[a-z,A-Z," "]{2,50}' required />
                                        </div>
                                        <div className="">
                                            <label htmlFor="emailId" className="formLabel">Email address</label>
                                            <input type="email" className="txt" id="emailId" value={email} name='email' onChange={e => setEmail(e.target.value)} placeholder="Enter Email Id" required />
                                        </div>
                                        <div className="">
                                            <label htmlFor="textarea" className="formLabel">Tell us about your project</label>
                                            <textarea className="txtarea" id="textarea" value={message} name='message' onChange={e => setMessage(e.target.value)} placeholder='maximum 500 words limit'></textarea>
                                        </div>
                                        <div className="">
                                            <div className="row">
                                                <div className="col-4">
                                                    {/* <button className='btn-submit btn-txt' disabled={verified} type='submit'>SUBMIT REQUEST</button> */}
                                                    <button className='btn-submit btn-txt' type='submit'>
                                                        SUBMIT REQUEST <img className='plane-icon' src={props.careerIcons.plane} alt='plane-icon' />
                                                    </button>
                                                </div>
                                                <div className="col-4">
                                                    {/* <ReCAPTCHA className='captcha' sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" onChange={onChange} /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                            }
                        </div>
                    </div>
                </div>
                <hr></hr>
            </div>
        </>
    )
}
