import { Component } from 'react';
import { ServiceProps } from './VarpProps';

export default class DigitalTransform extends Component<ServiceProps> {
  render() {
    window.scrollTo(0, 0);
    return (
      <>
        <div className="container-fluid cont-Services">
          <div className="container cont-galaxy">
            <div className="S-h1">
                <img className='img-border' src={this.props.serviceIcons.conPc} alt='Monitor' />
                <p className='h-1 Services-head-1'>
                  Digital Transformation
                </p>
              </div>
              <div className="S-h2">
                <p className='f-1'>
                  Redefine what's possible with our digital transformation solutions, streamline your operations,
                  and drive growth and success in the digital era.
                </p>
              </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="container contS-DT">
            <div className="text-center py-5">
              <p className='h-2'>
                Digital Transformation Services
              </p>
            </div>
            <div className="chart-box">
              <div className="fl">
                  <img className='chart' src={this.props.serviceIcons.businessChart} alt='bussiness-chart' />
                  <p className='h-3'>
                    Business Process Transformation
                  </p>
                  <p className='f-2'>
                    Repetitive processes, functions, workflows,
                    tools, and technologies are typically optimised
                    and automated in order to focus on higher-value
                    projects, business demands, and accelerate
                    time-to-market.
                  </p>
              </div>

              <div className="fc">
                  <img className='chart' src={this.props.serviceIcons.sendEmail} alt='send-email' />
                  <p className='h-3'>
                    Delivery Transformation
                  </p>
                  <p className='f-2'>
                    Agile delivery is more than just building
                    software with Scrum or Kanban. We can enable
                    your development teams achieve peak performance
                    by reinforcing a thorough understanding of
                    lean-agile, Automation, and CI/CD principles.
                  </p>
              </div>

              <div className="fr">
                  <img className='chart' src={this.props.serviceIcons.speedFill} alt='bussiness-chart' />
                  <p className='h-3'>
                    Engineering Transformation
                  </p>
                  <p className='f-2'>
                    We partner up with our clients to reduce
                    engineering inefficiencies by combining
                    approaches such as Devops, Automation,
                    and many more to improve product
                    development speed and agility.
                  </p>
                <div className="cls"></div>
              </div>

            </div>

          </div>
        </div>

      </>
    )
  }
}
