import { Component } from 'react';
import { HomeProps, ServiceProps } from './VarpProps';

export default function RoboticProcessAutomation (props : ServiceProps & HomeProps) {
  // render() {
    window.scrollTo(0, 0);
    return (
      <>
        <div className="container-fluid cont-Services">
          <div className="container cont-galaxy">
              <div className="S-h1">
                <img className='img-border' src={props.serviceIcons.tools} alt='tools-icon' />
                <p className='h-1 Services-head-1'>
                  Robotic Process Automation
                </p>
              </div>
              <div className="S-h2">
                <p className='f-1'>
                  Eliminate manual tasks, accelerate digital transformation, and achieve breakthrough results
                  with our RPA solutions and expertise, helping you stay ahead of the curve in today's fast-paced business environment.
                </p>
              </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="container cont-RPA">
            <div className="text-center py-5">
              <p className='h-2'>
                Robotic Process Automation<br/>Development Cycle
              </p>
            </div>
            <div className="RPA-box">
              <div className="py-5">
                {/* <img className='Sv-line' src={props.homeIcons.vectorLine2} alt='vector line' /> */}
                {/* <img className='v-dot1' src={props.serviceIcons.vector_dot} alt='vector-dot' />
                <img className='v-dot2' src={props.serviceIcons.vector_dot} alt='vector-dot' />
                <img className='v-dot3' src={props.serviceIcons.vector_dot} alt='vector-dot' />
                <img className='v-dot4' src={props.serviceIcons.vector_dot} alt='vector-dot' />
                <img className='v-dot5' src={props.serviceIcons.vector_dot} alt='vector-dot' />
                <img className='v-dot6' src={props.serviceIcons.vector_dot} alt='vector-dot' /> */}
                <div className="Logo">
                  {/* <img className='logo' src={props.serviceIcons.logo} alt='vector-arrow' /> */}
                  <img className='logo' src={props.homeIcons.logo} alt='vector-arrow' />
                  <p className='logo-text h-3'>RPA<br/>DEVELOPMENT PROCESS</p>
                </div>
                <div className='Num-One'>
                  <p className='Num'>1</p>
                  <img className='targetCircle' src={props.serviceIcons.targetCircles} alt='target-circles-icon' />
                  <p className='One-text h-3'>Identify the business processes</p>
                  <img className='arrow-1' src={props.serviceIcons.arrow1} alt='vector-arrow' />
                </div>
                <div className='Num-Two'>
                  <p className='Num'>2</p>
                  <img className='photoFilter' src={props.serviceIcons.fluentPhoto} alt='photoFilter-icon' />
                  <p className='Two-text h-3'>Define activities in each business process</p>
                  <img className='arrow-2' src={props.serviceIcons.arrow2} alt='vector-arrow' />
                </div>
                <div className='Num-Three'>
                  <p className='Num'>3</p>
                  <img className='codeFilled' src={props.serviceIcons.codeFilled} alt='codeFilled-icon' />
                  <p className='Three-text h-3'>Develop activities for identified business processes</p>
                  <img className='arrow-3' src={props.serviceIcons.arrow3} alt='vector-arrow' />
                </div>
                <div className='Num-Four'>
                  <p className='Num'>4</p>
                  <img className='fluentBot' src={props.serviceIcons.fluentBot} alt='fluentBot-icon' />
                  <p className='Four-text h-3'>Design a BOT using RPA Tools/custom BOT development</p>
                  <img className='arrow-4' src={props.serviceIcons.arrow4} alt='vector-arrow' />
                </div>
                <div className='Num-Five'>
                  <p className='Num'>5</p>
                  <img className='testTube' src={props.serviceIcons.testtube} alt='testTube-icon' />
                  <p className='Five-text h-3'>Integrate & test BOTs for the selected business processes</p>
                  <img className='arrow-5' src={props.serviceIcons.arrow5} alt='vector-arrow' />
                </div>
                <div className='Num-Six'>
                  <p className='Num'>6</p>
                  <img className='businessEye' src={props.serviceIcons.businessEye} alt='business-eye-icon' />
                  <p className='Six-text h-3'>Monitor and support for 4 weeks</p>
                  <img className='arrow-6' src={props.serviceIcons.arrow6} alt='vector-arrow' />
                </div>
              </div>
            </div>

          </div>
        </div>

      </>
    )
  // }
}
