import React, { Component } from 'react';
import { HomeProps } from './VarpProps';

export default class HomeSection2 extends Component<HomeProps> {
    render() {
        return (
            <div className="container cont2">
                <p className='cont2T'>
                    Trusted by
                </p>
            <div className="Tech-box p-2">
                <img className='img logo-jupiter' src={this.props.homeIcons.jupiter} alt='Jupiter' />
                <img className='img logo-bestinvest' src={this.props.homeIcons.bestinvest} alt='Bestinvest' />
                <img className='img logo-rdt' src={this.props.homeIcons.rdt} alt='RDT' />
                <img className='img logo-vector' src={this.props.homeIcons.vector} alt='Vector' />
                <img className='img logo-checkout' src={this.props.homeIcons.checkout} alt='Checkout' />
            </div>
            <div className="Tech-box">
                <img className='img logo-ifds' src={this.props.homeIcons.ifdservice} alt='IFDS' />
                <img className='img logo-investec' src={this.props.homeIcons.investec} alt='Investec' />
                <img className='img logo-optum' src={this.props.homeIcons.optum} alt='Optum' />
            </div>
        </div>
        )
    }
}
