import React, { Component } from 'react'
import { HomeProps } from './VarpProps'

export default class HomeSection4 extends Component<HomeProps> {
    render() {
        return (
            <div className='glob'>

                <div className="container cont4">
                    <div className="cont4-box1">
                        <img className='img-border' src={this.props.homeIcons.switch} alt='switch flow' />
                        <p className='h-2'>HOW WE WORK</p>
                    </div>
                    <div className="cont4-box2">
                        <p className='f-1 text-C5C5C5'>
                            Aim for Excellence, Learn from Experience,
                            Put in the effort, Stay the Course,
                            Persevere and Succeed!
                        </p>
                    </div>
                    <div className="v-line">
                        <img className='v-img' src={this.props.homeIcons.vectorLine} alt='vector line' />
                        <img className='v-img2' src={this.props.homeIcons.vectorLine2} alt='vector line' />
                        <button className='text-map btn-map map-btn'>YOU START HERE</button>
                            <img className='map' src={this.props.homeIcons.locationPin} alt='location-pin' />
                            <img className='cloud' src={this.props.homeIcons.cloud} alt='cloud' />
                            <img className='logo' src={this.props.homeIcons.logo} alt='logo' />
                        <div className="text-logo">
                            <p className='h-4'>
                                SHIP & SCALE
                            </p>
                            <p className='f-2 p-1-1'>
                                Multistage release strategy (IaC) that 
                                involves calculated cycles of Quality, 
                                Performance, and Security testing. 
                                After launch, we analyse and improve 
                                solution performance based on user 
                                feedback and monitoring to help 
                                product scale at warp speed.
                            </p>
                        </div>
                            <img className='unionLeft' src={this.props.homeIcons.unionLeft} alt='logo' />
                            <img className='unionRight' src={this.props.homeIcons.unionRight} alt='logo' />
                        <div className="phone-call">
                            <img className='icon icon-b' src={this.props.homeIcons.phone} alt='phone-call-icon' />
                        </div>
                        <div className="text-phone-call">
                            <p className='h-4'>
                                CONTACT US
                            </p>
                            <p className='f-2 p-1-1'>
                                Fill out the contact form protected
                                by NDA, book a calendar and schedule
                                a meeting with our experts.
                            </p>
                        </div>
                        <div className="tick">
                            <img className='icon icon-b' src={this.props.homeIcons.tick} alt='appointment-icon' />
                        </div>
                        <div className="text-tick">
                            <p className='h-4'>
                                CONSULTATION
                            </p>
                            <p className='f-2 p-1-1'>
                                Discuss the viability of
                                developing your product.
                            </p>
                        </div>
                        <div className="bill">
                            <img className='icon icon-b' src={this.props.homeIcons.bill} alt='cost-estimate-icon' />
                        </div>
                        <div className="text-bill">
                            <p className='h-4'>
                                COST ESTIMATE
                            </p>
                            <p className='f-2 p-1-1'>
                                Based on your high-level objectives,
                                we illustrate a proposal with budget
                                and timeline estimates.
                            </p>
                        </div>
                        <div className="telescope">
                            <img className='icon icon-b' src={this.props.homeIcons.telescope} alt='cost-estimate-icon' />
                        </div>
                        <div className="text-telescope">
                            <p className='h-4'>
                                DISCOVERY
                            </p>
                            <p className='f-2 p-1-1'>
                                Breakdown your Objectives into Key
                                Results using the why, how and
                                what framework.
                            </p>
                        </div>
                        <div className="construction">
                            <img className='icon icon-b' src={this.props.homeIcons.construction} alt='cost-estimate-icon' />
                        </div>
                        <div className="text-construction">
                            <p className='h-4'>
                                CONSTRUCTING MVP
                            </p>
                            <p className='f-2 p-1-1'>
                                Identifying and developing key features
                                that will allow the product to be
                                market-ready swiftly.
                            </p>
                        </div>
                        <div className="process">
                            <img className='icon icon-b' src={this.props.homeIcons.process} alt='cost-estimate-icon' />
                        </div>
                        <div className="text-process">
                            <p className='h-4'>
                                EXECUTION
                            </p>
                            <p className='f-2 p-1-1'>
                                Iterative model for product development
                                using agile/BDD methodologies.
                            </p>
                        </div>
                        <img className='cloud1' src={this.props.homeIcons.cloud1} alt='cloud' />
                    </div>
                </div>
            </div>
        )
    }
}
