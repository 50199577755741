import React, { Component } from 'react';
import { HomeIcons } from './ImgArray';
import HomeSection1 from './HomeSection1';
import HomeSection2 from './HomeSection2';
import HomeSection3 from './HomeSection3';
import HomeSection4 from './HomeSection4';
import HomeSection5 from './HomeSection5';
import HomeSection6 from './HomeSection6';

export default class Home extends Component {

    render() {

        window.scrollTo(0, 0);

        return (
            <>
                <HomeSection1 homeIcons={HomeIcons}/>
                <HomeSection2 homeIcons={HomeIcons}/>           
                <HomeSection3 homeIcons={HomeIcons}/>           
                <HomeSection4 homeIcons={HomeIcons}/>
                <HomeSection5 homeIcons={HomeIcons}/>
                <HomeSection6 homeIcons={HomeIcons}/>
            </>
        )
    }
}
