import React, { Component } from 'react'
import { HomeProps } from './VarpProps'

export default class HomeSection3 extends Component<HomeProps> {
    render() {
        return (
            <div className="container-fluid cont-galaxy">
                <div className="container cont3">
                    <div className="fl fl-box">
                        <img className='img-border p-img' src={this.props.homeIcons.logo} alt='Varp Techlabs Logo' />
                        <div className="">
                            <p className='h-2'>WHAT WE DO</p>
                        </div>
                    </div>
                    <div className="fr fr-box">
                        <p className='f-1'>
                            We collaborate with product companies to understand
                            market needs, develop with a platform mindset, and
                            release frequently, enabling businesses to launch
                            products into the market at warp speed by offering
                            product management, design, development, DevOps,
                            testing, security, and other services.
                        </p>
                    </div>
                    <div className="cls"></div>
                </div>
            </div>
        )
    }
}
