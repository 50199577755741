import { Component } from 'react';
import { ServiceProps } from './VarpProps';

export default class ProductEngineering extends Component<ServiceProps> {
  render() {
    window.scrollTo(0, 0);
    return (
      <>
        <div className="container-fluid cont-Services">
          <div className="container cont-galaxy">
            <div className="S-h1">
              <img className='img-border' src={this.props.serviceIcons.tools} alt='tools-icon' />
              <p className='h-1 Services-head-1'>
                Product Engineering
              </p>
            </div>
            <div className="S-h2">
              <p className='f-1'>
                Clearly outline the idea for a consumer market, the problems it can answer, the target market,
                and whether the business would be profitable enough to invest in.
                Then, lay out the foundation for the business concept.
              </p>
            </div>
          </div>
        </div>



        <div className="container-fluid">
          <div className="container contS-PE">
            <div className="text-center py-5">
              <p className='h-2'>
                Product Engineering Services
              </p>
            </div>
            <div className="row">
              <div className="fl card">
                <img className='fl img' src={this.props.serviceIcons.lightbulb} alt='lightbulb' />
                <p className='img-p h-2 fr'>
                  Ideas to Concept
                </p>
                <div className="cls"></div>
                <p className='f-1 p-1'>
                  Clearly outline the idea for a consumer market,
                  the problems it can answer, the target market,
                  and whether it is profitable enough to invest in.
                  Then, lay out the foundation for the business concept.
                </p>
              </div>
              <div className="fr card">
                <img className='fl img' src={this.props.serviceIcons.codeFilled} alt='code-filled' />
                <p className='img-p h-2 fr'>
                  Product Development
                </p>
                <div className="cls"></div>
                <p className='f-1 p-1'>
                  We adopt iterative experimentation, in which a hand-selected,
                  diverse team's members collaborate from beginning to end,
                  resulting in a continuous process of product development.
                  The solution emerges from team members' interactions rather
                  than following well defined, highly planned steps.
                </p>
              </div>
              <div className="cls"></div>
            </div>

            <div className="row">
              <div className="fl card">
                <img className='fl img' src={this.props.serviceIcons.native} alt='hybrid-networking' />
                <p className='img-p h-2 fr s'>
                  Hybrid & Native App Development
                </p>
                <div className="cls"></div>
                <p className='f-1 p-1'>
                  We emphasize user experience and innovation while designing.
                  We leverage the platform's expressiveness, flexible UI,
                  end-user experience, & lightning-fast load time to create apps.
                  Irrespective of which platform your app is being used on,
                  our development practices guarantee zero platform differences.
                </p>
              </div>
              <div className="fr card">
                <img className='fl img' src={this.props.serviceIcons.api_dep} alt='gateway-api' />
                <p className='img-p h-2 fr'>
                  API Development
                </p>
                <div className="cls"></div>
                <p className='f-1 p-1'>
                  Our development approach places APIs as "first class citizens".
                  We design APIs with a strong emphasis on data security and
                  scalability, ensuring they can handle high levels of traffic and
                  usage over extended periods. Our team also adheres to industry
                  standards and best practices to ensure optimal performance.
                </p>
              </div>
              <div className="cls"></div>
            </div>

            <div className="row">
              <div className="fl card">
                <img className='fl img' src={this.props.serviceIcons.lockCog} alt='setting-service' />
                <p className='img-p h-2 fr'>
                  Platform Engineering
                </p>
                <div className="cls"></div>
                <p className='f-1 p-1'>
                  Our focus is on enhancing developer efficiency and enabling
                  swift feature delivery while ensuring scalability, performance,
                  and security of applications. We strive to establish a stable
                  and dependable groundwork for application development by
                  centralizing and maintaining infrastructure and services.
                </p>
              </div>
              <div className="fr card">
                <img className='fl img' src={this.props.serviceIcons.cloudCog} alt='cloud-service' />
                <p className='img-p h-2 fr'>
                  SaaS Implementation
                </p>
                <div className="cls"></div>
                <p className='f-1 p-1'>
                  We adhere to the 12 factor methodology to make software as a
                  service, utilizing established ways of working that emphasize
                  speed and dependability. This approach guarantees that our
                  applications are portable, compatible with  cloud platforms,
                  can run on uniform environments, and scale up seamlessly.
                </p>
              </div>
              <div className="cls"></div>
            </div>
          </div>

        </div>
      </>
    )
  }
}