export const HomeIcons = {
    // navLogo : './Images/Menu-navbar/varp-logo-white.svg',
    navLogo : './Images/Menu-navbar/Logo-svg.SVG',
    logo : './Images/Menu-navbar/varp-logo.svg',
    glob : './Images/home-icons/glob.png',
    jupiter : "./Images/home-icons/jupiter_logo.svg",
    bestinvest : "./Images/home-icons/bestinvest_logo.svg",
    rdt : "./Images/home-icons/rdt-logo.svg",
    vector : "./Images/home-icons/vector-logo.svg",
    vectorLine : './Images/home-icons/vector-line.svg',
    vectorLine2 : './Images/home-icons/v-line2.svg',
    checkout : "./Images/home-icons/checkout-logo.svg",
    ifdservice : "./Images/home-icons/ifdservice-logo.svg",
    investec : "./Images/home-icons/investec-logo.svg",
    optum : "./Images/home-icons/optum-logo.svg",
    switch : "./Images/home-icons/switch_flow.svg",
    locationPin : './Images/home-icons/location-pin.svg',
    cloud : './Images/home-icons/cloud.svg',
    cloud1 : './Images/home-icons/cloud1.svg',
    unionLeft : './Images/home-icons/union-left.svg',
    unionRight : './Images/home-icons/union-right.svg',
    phone : './Images/home-icons/phone-call-icon.svg',
    tick : './Images/home-icons/double-tick-icon.svg',
    bill : './Images/home-icons/bill-cost-icon.svg',
    telescope : './Images/home-icons/telescope-icon.svg',
    construction : './Images/home-icons/construction-machine-icon.svg',
    process : './Images/home-icons/process-icon.svg',
    dns : './Images/home-icons/dns-services-icon.svg',
    tools : './Images/home-icons/tools-icon.svg',
    pc : './Images/home-icons/pc-icon.svg',
    robot : './Images/home-icons/robot-icon.svg',
    cog : './Images/home-icons/cog-hand-hold-icon.svg',
    db : './Images/home-icons/database-server-icon.svg',
    dotnet : './Images/home-icons/dot-net-icon.svg',
    java : './Images/home-icons/java-icon.svg',
    python : './Images/home-icons/python-icon.svg',
    golan : './Images/home-icons/golan-icon.svg',
    angular : './Images/home-icons/angular-icon.svg',
    react : './Images/home-icons/react-icon.svg',
    figma : './Images/home-icons/figma-icon.svg',
    node : './Images/home-icons/nodejs-icon.svg',
    aws : './Images/home-icons/AWS-icon.svg',
    gcloud : './Images/home-icons/google-cloud-icon.svg',
    azure : './Images/home-icons/azure-icon.svg',
    mongodb : 'Images/home-icons/mongoDB-icon.svg',
    docker : './Images/home-icons/docker-icon.svg',
    apacheM : './Images/home-icons/apache-meter-icon.svg',
    sqlserver : './Images/home-icons/sql-server-icon.svg',
    selenium : './Images/home-icons/selenium-icon.svg',
    postgre : './Images/home-icons/postgreSQL-icon.svg',
    kuber : './Images/home-icons/kubernetes-icon.svg',
    uipath : './Images/home-icons/uipath-icon.svg',
    awsdb : './Images/home-icons/aws-dynamodb.svg',
    arrow : './Images/home-icons/read-arrow.svg',
    Btnarrow : './Images/home-icons/arrow-left-icon.svg',
    envelope : './Images/home-icons/envelope.svg',
    linkedin : './Images/home-icons/linkedin-icon.svg',
    twitter : './Images/home-icons/twitter-icon.svg',
  }

  export const ServiceIcons = {
    tools : './Images/services-icons/tools.svg',
    robot : './Images/services-icons/robot-icon.svg',
    handCog : './Images/services-icons/cog-hand-hold-icon.svg',
    lightbulb : './Images/services-icons/lightbulb-icon.svg',
    codeFilled : './Images/services-icons/code-filled-icon.svg',
    native : './Images/services-icons/hybrid-networking-icon.svg',
    api_dep : './Images/services-icons/gateway-api-icon.svg',
    lockCog : './Images/services-icons/setting-services-icon.svg',
    cloudCog : './Images/services-icons/cloud-services-icon.svg',
    conPc : './Images/services-icons/construction-pc-icon.svg',
    businessChart : './Images/services-icons/business-chart-graph-icon.svg',
    sendEmail : './Images/services-icons/send-email-icon.svg',
    speedFill : './Images/services-icons/speed-fill-icon.svg',
    arrow1 : './Images/services-icons/vector-arrow-1.svg',
    arrow2 : './Images/services-icons/vector-arrow-2.svg',
    arrow3 : './Images/services-icons/vector-arrow-3.svg',
    arrow4 : './Images/services-icons/vector-arrow-4.svg',
    arrow5 : './Images/services-icons/vector-arrow-5.svg',
    arrow6 : './Images/services-icons/vector-arrow-6.svg',
    targetCircles : './Images/services-icons/target-fashion-circles-center-icon.svg',
    fluentPhoto : './Images/services-icons/fluent-photo-filter-filled-icon.svg',
    fluentBot : './Images/services-icons/fluent-bot-filled-icon.svg',
    testtube : './Images/services-icons/test-tube-fill-icon.svg',
    businessEye : './Images/services-icons/business-chart-eye-icon.svg',
    logo : './Images/Menu-navbar/varp-logo.png',
    vector_dot : './Images/services-icons/vector-dot.svg',
    menu : './Images/Menu-navbar/menu-icon.svg',
  }

  export const CareerIcons = {
    team : './Images/career-icons/friend-team-icon.svg',
    arrowDown : './Images/career-icons/arrow-down.svg',
    time : './Images/career-icons/time-icon.svg',
    happyMood : './Images/career-icons/happy-mood-icon.svg',
    brain : './Images/career-icons/brain-icon.svg',
    earth : './Images/career-icons/earth-icon.svg',
    founder : './Images/founder.svg',
    co_founder_1 : './Images/co-founder-1.svg',
    co_founder_2 : './Images/co-founder-2.svg',
    map_location : './Images/career-icons/location-map-pin-icon.svg',
    plane : './Images/Menu-navbar/paper-plane-icon.svg',
  }
